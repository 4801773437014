import { FC } from "react";

import styles from './styles.module.scss'
import { admissionModel } from "widgets/admission";
import { popoutModel } from "shared/ui/popout-root";
import { TTraing } from "widgets/admission/model";

export const TypeTraining: FC = () => {

    const onTraining = (t: TTraing) => {
        admissionModel.events.setTrainingSelect({
            id: t
        })
        popoutModel.events.setPopout('admission')
    }

    return (
        <div
            className={styles.typeTraining} id="typeTraining"
        >
            <div className={styles.__content}>

                <div className={styles.group}>

                    <div className={styles.offline}>
                        <div
                            onClick={() => onTraining('offline_3')}
                            className={`${styles.li} ${styles.t3}`}
                        >
                            <div className={styles.name}>
                                <div>Оффлайн</div>
                                <div><span>Группа</span></div>
                            </div>
                            <div className={styles.price}>12 200₽</div>
                        </div>
                        <div
                            onClick={() => onTraining('offline_2')}
                            className={`${styles.li} ${styles.t2}`}
                        >
                            <div className={styles.name}>
                                <div>Оффлайн</div>
                                <div><span>Закрытая группа</span></div>
                            </div>
                            <div className={styles.price}>16 200₽</div>
                        </div>
                        <div
                            onClick={() => onTraining('offline_1')}
                            className={`${styles.li} ${styles.t1}`}
                        >
                            <div className={styles.name}>
                                <div>Оффлайн</div>
                                <div><span>Индивидуально</span></div>
                            </div>
                            <div className={styles.price}>19 500₽</div>
                        </div>
                    </div>
                    <div className={styles.online}>
                        <div
                            onClick={() => onTraining('online_3')}
                            className={`${styles.li} ${styles.t3}`}>
                            <div className={styles.name}>
                                <div>Онлайн</div>
                                <div><span>Группа</span></div>
                            </div>
                            <div className={styles.price}>9 500₽</div>
                        </div>
                        <div
                            onClick={() => onTraining('online_2')}
                            className={`${styles.li} ${styles.t2}`}
                        >
                            <div className={styles.name}>
                                <div>Онлайн</div>
                                <div><span>Закрытая группа</span></div>
                            </div>
                            <div className={styles.price}>13 600₽</div>
                        </div>
                        <div
                            onClick={() => onTraining('online_1')}
                            className={`${styles.li} ${styles.t1}`}
                        >
                            <div className={styles.name}>
                                <div>Онлайн</div>
                                <div><span>Индивидуально</span></div>
                            </div>
                            <div className={styles.price}>16 200₽</div>
                        </div>
                    </div>


                </div>

            </div>
        </div>
    )
}
