import { getPlatformNative } from "shared/lib/get-platform-native"
import { Header } from "shared/ui/header/ui"
import { Main } from "widgets/main/ui"
import { Slider } from "widgets/slider/ui"
import { useApp } from "./hooks/use-app"
import { Process } from "widgets/process/ui"
import { Teachers } from "widgets/teachers/ui"
import { Courses } from "widgets/courses/ui"
import { Feedback } from "widgets/feedback/ui"
import { Footer } from "widgets/footer/ui"
import { Popouts } from "widgets/app/popouts"

const _platform = getPlatformNative()

const App = () => {

    console.log('App')
    console.log('Version 1.2.1')

    useApp()

    return (
        <div className={`app ${_platform}`}>
            <Popouts />

            <Header />
            <Main />
            <Slider />
            <Courses />
            <Process />
            <Feedback />
            
            {/* <Teachers /> */}
            <Footer />
        </div>

    )
}
export default App